@media screen and (max-width: 414px) {
  #freebiesComing{margin-left:8em;}
}

.section-ebook{border: 1px solid #f5f6f7;-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);}
.nav-navbar .nav .text-ideate{color:#8558ce;}
.nav-navbar .nav .text-define{color:#34e3ed;}
.nav-navbar .nav .text-develop{color:#ed7b34;}
.nav-navbar .nav .text-deliver{color:#39cc6e;}
.backdrop-navbar{background-color: rgba(255, 255, 255, 0.2);}
.text-deliver{color:#39cc6e !important;}
.text-develop{color:#ed7b34 !important;}
.text-define{color:#34e3ed !important;}
.text-ideate{color:#8558ce !important;}
.text-turquoise{color:#3ac9ff !important;}
.btn-turquoise{color:#fff !important;background-color:#3ac9ff !important;}
.btn-outline-turquoise{color:#3ac9ff !important;border-color:#3ac9ff !important;}
.btn-develop{background-color:#ed7b34 !important;}
.section-vision{border-top-right-radius:30px;border-top-left-radius:30px;border: 1px solid #f5f6f7;-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);}
.section-vision2{border-top-right-radius:30px;border-top-left-radius:30px;border-bottom-left-radius:30px;border-bottom-right-radius:30px;border: 1px solid #f5f6f7;-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);}
.contact-header{height:80vh;}
.icon-box-ideate{background-color:#8558ce;}
.hover-shadow-blue:hover {
  -webkit-box-shadow: 0 0 18px rgba(204, 249, 231, 0.6);
          box-shadow: 0 0 18px rgba(204, 249, 231, 0.6)}
.hover-shadow-purple:hover {
  -webkit-box-shadow: 0 0 18px rgba(238, 204, 255, 0.6);
          box-shadow: 0 0 18px rgba(238, 204, 255, 0.6)}
.hover-shadow-green:hover {
  -webkit-box-shadow: 0 0 18px rgba(179, 255, 204, 0.6);
          box-shadow: 0 0 18px rgba(179, 255, 204, 0.6)}
.icon-services{width:30%;}
.shadow-custom{
  z-index:99;
  -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.95);
          box-shadow: 0 0 8px rgba(0,0,0,0.95)}
.project{background-size:cover;}
/*5 column layout*/
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

/*media-queries*/
.col-xs-15 {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
.col-sm-15 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}
.col-xs-25 {
width: 40%;
float: left;
}

.col-xs-35 {
width: 60%;
float: left;
}

.col-xs-45 {
width: 80%;
float: left;
}

/*5 column layout end*/       
.trans--grow{
  -webkit-transition: width 0.3s ease-out; /* For Safari 3.1 to 6.0 */
  transition: width 0.3s  ease-out;
  width : 0%;
}
.trans--grow2{
  -webkit-transition: width 1s ease-out; /* For Safari 3.1 to 6.0 */
  transition: width 1s  ease-out;
  width : 0%;
}
.trans--grow3{
  -webkit-transition: width 1.8s ease-out; /* For Safari 3.1 to 6.0 */
  transition: width 1.8s  ease-out;
  width : 0%;
}
.grow{
  width:100%;
}
.hr1{
  margin-left:0;
  background-color:#8558ce;
}
.hr2{
  margin-left:0;
  background-color:#34e3ed;
}
.hr3{
  margin-left:0;
  background-color:#39cc6e;
}


#wrapper {
  margin-left: 15%;
}

.image {
  position: absolute;
  width: 500px;
  height: 500px;
}

.img1 {
  -webkit-animation: appear 1s;
  animation: appear 1s;
}

.img2 {
  -webkit-animation: appear 3s;
  animation: appear 3s;
  background-color: orange;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes appear {
    0%   {opacity: 0; margin-left: -25%;}
    100% {opacity: 1;}
}

/* IE */
@keyframes appear {
    0%   {opacity: 0; margin-left: -25%;}
    100% {opacity: 1;}
}

.img1 {
  z-index: 1;
}
.img2 {
  z-index: 2;
  margin-left: 100px;
  margin-top: 100px;
}

.img1, .img2,{
  transition: all 2s;}

.tilter {
  position: relative;
  display: block;
  flex: none;
  color: #fff;
  perspective: 1000px;
  @include media-breakpoint-down(xs) { 
  .tilter{margin: 1.5em 6.8em;} }
}

@media (max-width: 768px) {
  .tilter {
    width: 240px;
    height: 332px;
  }
}

@media (min-width: 768px) {
  .tilter {
    width: 300px;
    height: 415px;
  }
}

@media (max-width: 768px) {
   .img-small {
     visibility: visible;
   }

   .img-medium {
     visibility: hidden;
   }
}

@media (min-width: 768px) {
  .img-small {
     visibility: hidden;
  }
  .img-medium {
     visibility: visible;
  }
}

.tilter * {
  pointer-events: none;
}

.tilter:hover,
.tilter:focus {
  color: #fff;
  outline: none;
}

.tilter__figure,
.tilter__image {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
}

.tilter__figure > * {
  transform: translateZ(0px); /* Force correct stacking order */
}

.tilter__figure {
  position: relative;
}

.tilter__figure::before {
  content: '';
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  box-shadow: 0 30px 20px rgba(35,32,39,0.5);
}

.tilter__deco {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.tilter__deco--overlay {
  background-image: linear-gradient(45deg, rgba(226, 60, 99, 0.4), rgba(145, 58, 252, 0.4), rgba(16, 11, 192, 0.4));
}

.tilter__deco--shine div {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0.25) 50%, transparent 100%);
}

.tilter__deco--lines {
  fill: none;
  stroke: #fff;
  stroke-width: 1.5px;
}

.tilter__caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 4em;
}

.tilter__title {
  font-family: Dosis, sans-serif;
  font-size: 3.5em;
  font-weight: normal;
  line-height: 1;
  margin: 0;
  text-align:center;
}

.tilter__description {
  font-size: 0.85em;
  margin: 1em 0 0 0;
  letter-spacing: 0.15em;
}

.error-message {
  width: 100%;
  color: #D8000C;
  font-size: 2em;
}

.media-container {
  position: relative;
  width: 100%;
  height: 30em;
  display: table;
}

.hidden {
  display: none;
}

.media-container .center {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.btn-black {
  background-color: black;
}
