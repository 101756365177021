
/* -------------------------------- 
Shared style
-------------------------------- */
.cd-btn {
  display: inline-block;
  padding: 1.6em 2.4em;
  font-size: 1.4rem;
  letter-spacing: .15em;
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  -webkit-transition: box-shadow .3s;
  transition: box-shadow .3s;
}
.cd-btn:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

/* Main Content */
.cd-main-content {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 30em;
  /* vertically align its content */
  display: table;
}
.cd-main-content .center {
  /* vertically align inside parent element */
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

/* -------------------------------- 
Gummy Effect
-------------------------------- */
.gummy-transition {
  font-family: "Nunito", sans-serif;
  color: #f2f2f2;
}
.gummy-transition h1, .gummy-transition h2 {
  margin-bottom: .4em;
  font-size: 4rem;
  font-weight: 700;
}
.gummy-transition .cd-btn {
  background: #3f5074;
  color: #f2f2f2;
  border-radius: 50em;
}
.gummy-transition .cd-main-content {
  background: #ffffff;
  opacity: 1;
}
.gummy-transition .cd-main-content.hiding {
  opacity: 0;
  transition: opacity 0.5s linear;
}
.gummy-transition .cd-main-content.hidden {
  display: none;
}
@media only screen and (min-width: 768px) {
  .gummy-transition h1 {
    font-size: 7.2rem;
  }
  .gummy-transition h2 {
    font-size: 5.8rem;
  }
}
